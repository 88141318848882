@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.nav {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: clamp(10px, 1.7vw, 24px) 0;

  .logo {
    max-width: 217px;
    width: 100%;
    margin-right: 24px;
  }

  &__inner {
    @include flex-v-center;

    @include media(tablet) {
      position: fixed;
      left: 0;
      top: calc((var(--header-height) * 1) - 5px);
      z-index: -1;
      flex-direction: column;
      justify-content: flex-start;
      overflow-y: auto;
      padding: 30px 16px;
      width: 100%;
      height: calc(var(--vh));
      background-color: var(--color-cream-100);
      transform: translateX(150%);
      transition-property: transform;
      transition-duration: 0.4s;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    margin-right: clamp(24px, 11vw, 154px);

    @include media(tablet-wide) {
      margin-right: 24px;
    }

    @include media(tablet) {
      margin-right: 0;
      margin-bottom: 30px;
      flex-direction: column;
      gap: 30px;
    }
  }

  &__item {
    z-index: 5;
  }

  &__link {
    padding: 8px 24px;
    text-transform: capitalize;
    background-color: transparent;
    border-radius: var(--radius-main);

    &.true,
    &:hover {
      background-color: var(--color-white);
    }

    &.true {
      font-weight: var(--fw-700);

      @include media(tablet) {
        background-color: var(--color-cream-100);
        text-decoration: underline;
        text-underline-offset: 4px;
      }
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    gap: 7px;

    @include media(tablet) {
      flex-direction: column;
      gap: 30px;
    }
  }
}