@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

h1,
h2,
h3,
.title,
.price {
  font-weight: var(--fw-700);
  line-height: 1.21;
}

h1 {
  font-size: clamp(32px, 6.7vw, 96px);
}

h2 {
  font-size: clamp(28px, 4.5vw, 64px);
}

h3,
.price {
  font-size: clamp(20px, 1.7vw, 24px);
}

.title {
  font-size: clamp(24px, 2.5vw, 36px);
  color: var(--color-brown-400);
}

.text {
  font-size: 16px;
  line-height: 1.5;
}

.text,
p {
  color: var(--color-brown-460);
}

a,
button {
  width: fit-content;
  font-weight: var(--fw-500);
  font-size: 18px;
  line-height: 1.21;
  overflow: hidden;
}

.label,
.copyright {
  font-size: 16px;
  line-height: 1.21;
  color: var(--color-brown-400);
}

.price {
  color: var(--color-brown-460);
}
  @include media(mobile-m) {
    .label {
      font-size: 14px;
    }
  }