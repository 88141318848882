@use '../utils/functions/' as *;
@use '../utils/mixins' as *;

.policy {
  h1 {
    font-size: clamp(28px, 4.5vw, 64px);
  }

  h2 {
    font-size: 28px;
  }

  h1 {
    margin-bottom: clamp(24px, 3.4vw, 48px);
  }

  h2 {
    margin-bottom: clamp(16px, 2.2vw, 32px);
  }

  .policy__content:not(:last-child) {
    margin-bottom: clamp(24px, 3.4vw, 48px);
  }

  .policy__content > *:not(:last-child, h2) {
    margin-bottom: clamp(16px, 2.2vw, 32px);
  }

  ul {
    padding-left: clamp(16px, 2.2vw, 32px);
  }

  li {
    padding-left: 32px;
    position: relative;

    &::before {
      content: '\2714';
      position: absolute;
      left: 0;
    }

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }

  a {
    font-size: 16px;
    color: var(--color-brown-400);
    text-decoration: underline;
    text-underline-offset: 6px;
    font-size: 18px;
  }

  strong {
    font-size: clamp(18px, 3vw, 22px);
    font-weight: var(--fw-500);
  }

  &__inner {
    padding: clamp(10px, 3vw, 32px);
    background-color: var(--color-white);
    border-radius: var(--radius-main);

    @include media(tablet) {
      border-radius: var(--radius-mobile);
    }
  }
}
