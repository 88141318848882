@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.card {
  $root: &;

  &__item {
    position: relative;
    padding: clamp(16px, 2.2vw, 32px) clamp(0px, 2.2vw, 32px);
    background-color: var(--color-white);
    border-radius: var(--radius-main);

    img {
      width: 100%;
      margin-bottom: clamp(16px, 1.7vw, 24px);
      border-radius: var(--radius-main);
    }

    h3 {
      margin-bottom: 8px;
    }
  }

  &__link {
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      z-index: 2;
      transition: filter 0.4s ease-in-out;
    }
  }

  &__extra {
    @include flex-all-sb;
  }

  &:not(&--slider) {
    #{$root}__items {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: 1fr;
      gap: clamp(16px, 1.7vw, 24px) clamp(10px, 1.7vw, 24px);
    }
  }

  &--slider {
    #{$root}__item {
      height: auto;
      padding-bottom: 112px;
    }

    #{$root}__content {
      padding-right: 246px;
    }
  }

  &--num {
    #{$root}__items {
      counter-reset: num;
    }

    #{$root}__item {
      position: relative;
      min-height: clamp(250px, 29vw, 417px);
      padding: clamp(16px, 1.7vw, 24px) clamp(16px, 1.7vw, 24px) clamp(100px, 13.4vw, 184px);
      overflow: hidden;

      &::before {
        content: "0" counter(num);
        counter-increment: num;
        position: absolute;
        right: 0;
        bottom: -54px;
        font-size: clamp(92px, 12vw, 164px);
        line-height: 1;
        color: var(--color-brown-460);
      }
    }
  }

  &--destinations {
    #{$root}__item {
      padding-bottom: 86px;
    }
  }

  &--tours {
    #{$root}__article {
      @include flex-sb;
      flex-direction: column;
      gap: clamp(16px, 1.7vw, 24px);
      height: 100%;
    }
  }

  @include media(tablet-wide) {
    &--num {
      #{$root}__item {
        &::before {
          bottom: -24px;
        }
      }
    }

    &--slider {
      #{$root}__item {
        padding-bottom: 60px;
      }

      #{$root}__content {
        padding-right: 0;
      }
    }
  }

  @include media(tablet) {
    &__item {
      border-radius: var(--radius-mobile);

      img {
        border-radius: var(--radius-mobile);
      }
    }

    &:not(&--slider) {
      #{$root}__items {
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: auto;
      }

      #{$root}__item {
        &:last-of-type {
          grid-column: span 2;

          img {
            aspect-ratio: 2/1;
          }
        }
      }
    }
  }

  @include media(mobile-l) {
    &--slider {
      #{$root}__item {
        img {
          aspect-ratio: 3/2;
        }
      }
    }
  }

  @include media(mobile-m) {
    &:not(&--slider) {
      #{$root}__items {
        grid-template-columns: 1fr;
      }

      #{$root}__item {
        &:last-of-type {
          grid-column: auto;
        }

        img {
          aspect-ratio: 2/1;
        }
      }
    }
  }
}