/* stylelint-disable alpha-value-notation */
/* stylelint-disable color-function-notation */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

:root {
  --content-width: 1216px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // font-family
  --font-family-primary: "Inter", sans-serif;

  // font-weight
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;

  // color
  --color-white: #ffffff;
  --color-black: #000000;

  --color-cream-100: #F5F5F5;
  --color-brown-400: #5A2C1C;
  --color-brown-460: rgba(90, 44, 28, 0.6);

  //  radius
  --radius-main: 32px;
  --radius-mobile: 16px;
  --radius-full: 50%;

}