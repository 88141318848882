@import "https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap";
:root {
  --content-width: 1216px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width)  + (var(--container-offset) * 2));
  --font-family-primary: "Inter", sans-serif;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --color-white: #fff;
  --color-black: #000;
  --color-cream-100: #f5f5f5;
  --color-brown-400: #5a2c1c;
  --color-brown-460: #5a2c1c99;
  --radius-main: 32px;
  --radius-mobile: 16px;
  --radius-full: 50%;
}

.checkbox__field:checked + .checkbox__content:after {
  opacity: 1;
}

.checkbox__field:focus + .checkbox__content:before {
  outline: 1px solid var(--color-gray-100);
  outline-offset: 1px;
}

.checkbox__field:disabled + .checkbox__content {
  opacity: .4;
  pointer-events: none;
}

html, body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-500);
  letter-spacing: 0;
  color: var(--color-brown-400);
  background-color: var(--color-cream-100);
  font-optical-sizing: auto;
  font-size: clamp(16px, 2vw, 20px);
  font-style: normal;
  line-height: 1.5;
}

.page__body {
  min-width: 320px;
  min-height: var(--vh);
}

.page.open {
  overflow: hidden;
}

.site-container {
  min-height: var(--vh);
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr;
  display: grid;
  position: relative;
}

.site-container:before {
  content: "";
  z-index: -1;
  width: 100%;
  height: 100%;
  background-image: url("../images/body-bg.svg");
  background-position: 100% 0;
  background-repeat: repeat-y;
  position: absolute;
  inset: 0;
}

@media only screen and (max-width: 992px) {
  .site-container:before {
    background-size: 40%;
  }
}

.main {
  padding-top: var(--header-height);
}

.container {
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
  margin: 0 auto;
}

.section {
  padding: clamp(30px, 6.2vw, 88px) 0;
}

.section__title, .section--more > :nth-last-child(2) {
  margin-bottom: clamp(24px, 3.4vw, 48px);
}

.hidden {
  display: none !important;
}

.sr-only {
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.dis-scroll {
  width: 100%;
  height: 100dvh;
  height: calc(var(--vh, 1vh) * 100);
  overscroll-behavior: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

h1, h2, h3, .title, .price {
  font-weight: var(--fw-700);
  line-height: 1.21;
}

h1 {
  font-size: clamp(32px, 6.7vw, 96px);
}

h2 {
  font-size: clamp(28px, 4.5vw, 64px);
}

h3, .price {
  font-size: clamp(20px, 1.7vw, 24px);
}

.title {
  color: var(--color-brown-400);
  font-size: clamp(24px, 2.5vw, 36px);
}

.text {
  font-size: 16px;
  line-height: 1.5;
}

.text, p {
  color: var(--color-brown-460);
}

a, button {
  width: -moz-fit-content;
  width: fit-content;
  font-weight: var(--fw-500);
  font-size: 18px;
  line-height: 1.21;
  overflow: hidden;
}

.label, .copyright {
  color: var(--color-brown-400);
  font-size: 16px;
  line-height: 1.21;
}

.price {
  color: var(--color-brown-460);
}

@media only screen and (max-width: 576px) {
  .label {
    font-size: 14px;
  }
}

.header {
  z-index: 10;
  width: 100%;
  background-color: #0000;
  transition-property: transform;
  transition-duration: .15s;
  position: fixed;
  top: 0;
}

.header.hide {
  transform: translateY(-100%);
}

.header.hide, .header.scroll {
  background-color: var(--color-cream-100);
}

@media only screen and (max-width: 992px) {
  .header {
    background-color: var(--color-cream-100);
  }
}

.nav {
  justify-content: space-between;
  align-items: center;
  padding: clamp(10px, 1.7vw, 24px) 0;
  display: flex;
  position: relative;
}

.nav .logo {
  max-width: 217px;
  width: 100%;
  margin-right: 24px;
}

.nav__inner {
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .nav__inner {
    left: 0;
    top: calc(var(--header-height) * 1 - 5px);
    z-index: -1;
    width: 100%;
    height: calc(var(--vh));
    background-color: var(--color-cream-100);
    flex-direction: column;
    justify-content: flex-start;
    padding: 30px 16px;
    transition-property: transform;
    transition-duration: .4s;
    position: fixed;
    overflow-y: auto;
    transform: translateX(150%);
  }
}

.nav__list {
  align-items: center;
  margin-right: clamp(24px, 11vw, 154px);
  display: flex;
}

@media only screen and (max-width: 1180px) {
  .nav__list {
    margin-right: 24px;
  }
}

@media only screen and (max-width: 992px) {
  .nav__list {
    flex-direction: column;
    gap: 30px;
    margin-bottom: 30px;
    margin-right: 0;
  }
}

.nav__item {
  z-index: 5;
}

.nav__link {
  text-transform: capitalize;
  border-radius: var(--radius-main);
  background-color: #0000;
  padding: 8px 24px;
}

.nav__link.true, .nav__link:hover {
  background-color: var(--color-white);
}

.nav__link.true {
  font-weight: var(--fw-700);
}

@media only screen and (max-width: 992px) {
  .nav__link.true {
    background-color: var(--color-cream-100);
    text-underline-offset: 4px;
    text-decoration: underline;
  }
}

.nav__btns {
  align-items: center;
  gap: 7px;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .nav__btns {
    flex-direction: column;
    gap: 30px;
  }
}

[class].btn {
  width: -moz-fit-content;
  width: fit-content;
  border: 1.5px solid var(--color-brown-400);
  border-radius: var(--radius-main);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-500);
  text-transform: capitalize;
  text-align: center;
  white-space: nowrap;
  color: var(--color-brown-400);
  background-color: var(--color-cream-100);
  margin: 0;
  padding: 15px 23px;
  font-size: clamp(16px, 2vi, 18px);
  line-height: 1;
  transition-property: background-color, color;
  transition-duration: .4s;
  transition-timing-function: ease-in-out;
  display: block;
  position: relative;
}

[class].btn--reverse, [class].btn:hover {
  color: var(--color-white);
  background-color: var(--color-brown-400);
}

[class].btn--reverse:hover {
  background-color: var(--color-white);
  color: var(--color-blue-700);
}

[class].btn--centered {
  margin-inline: auto;
  font-size: 16px;
}

@media only screen and (max-width: 768px) {
  [class].btn--centered {
    width: 100%;
    border-radius: var(--radius-mobile);
  }
}

[class].btn--bold {
  font-weight: var(--fw-600);
}

.burger {
  --line-height: 2px;
  --buger-size: 20px;
  --move: calc(var(--buger-size) / 3);
  --move-decrement: calc(var(--move) * -1);
  --transition-time: .5s;
  --lines-color: var(--color-brown-400);
  width: calc(var(--buger-size)  + 5px);
  height: var(--buger-size);
  transition: opacity var(--transition-time) ease;
  background-color: #0000;
  display: none;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.burger span {
  z-index: -1;
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  transition: transform var(--transition-time) ease, background-color var(--transition-time) ease .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.burger span:before, .burger span:after {
  content: "";
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  animation-duration: .4s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
  position: absolute;
  left: 0;
}

.burger span:before {
  transform: translateY(var(--move-decrement)) rotate(0);
}

.burger span:after {
  transform: translateY(var(--move)) rotate(0);
}

.burger:hover {
  opacity: 1;
}

@media only screen and (max-width: 992px) {
  .burger {
    display: block;
  }
}

.closed.opened span {
  background-color: var(--lines-color);
  transition: background-color .2s;
}

.closed.opened span:before {
  animation-name: toplineburger;
}

.closed.opened span:after {
  animation-name: bottomlineburger;
}

.opened.nav:not(.nav--footer) .nav__inner {
  transform: translateX(0);
}

.opened span {
  background-color: #0000;
  transition: background-color .2s;
}

.opened span:before, .opened span:after {
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
}

.opened span:before {
  animation-name: toplinecross;
}

.opened span:after {
  animation-name: bottomlinecross;
}

.hero {
  padding-top: clamp(30px, 4.5vw, 64px);
}

.hero__content {
  z-index: 2;
  width: 100%;
  text-align: center;
  position: relative;
}

.hero h1, .hero img {
  margin-bottom: 24px;
}

.hero img {
  border-radius: var(--radius-main);
}

.hero__texts {
  max-width: 596px;
  width: 100%;
  margin-inline: auto;
}

@media only screen and (max-width: 992px) {
  .hero img {
    border-radius: var(--radius-mobile);
  }
}

.card__item {
  background-color: var(--color-white);
  border-radius: var(--radius-main);
  padding: clamp(16px, 2.2vw, 32px) clamp(0px, 2.2vw, 32px);
  position: relative;
}

.card__item img {
  width: 100%;
  border-radius: var(--radius-main);
  margin-bottom: clamp(16px, 1.7vw, 24px);
}

.card__item h3 {
  margin-bottom: 8px;
}

.card__link:before {
  content: "";
  z-index: 2;
  transition: filter .4s ease-in-out;
  position: absolute;
  inset: 0;
}

.card__extra {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.card:not(.card--slider) .card__items {
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  gap: clamp(16px, 1.7vw, 24px) clamp(10px, 1.7vw, 24px);
  display: grid;
}

.card--slider .card__item {
  height: auto;
  padding-bottom: 112px;
}

.card--slider .card__content {
  padding-right: 246px;
}

.card--num .card__items {
  counter-reset: num;
}

.card--num .card__item {
  min-height: clamp(250px, 29vw, 417px);
  padding: clamp(16px, 1.7vw, 24px) clamp(16px, 1.7vw, 24px) clamp(100px, 13.4vw, 184px);
  position: relative;
  overflow: hidden;
}

.card--num .card__item:before {
  content: "0" counter(num);
  counter-increment: num;
  color: var(--color-brown-460);
  font-size: clamp(92px, 12vw, 164px);
  line-height: 1;
  position: absolute;
  bottom: -54px;
  right: 0;
}

.card--destinations .card__item {
  padding-bottom: 86px;
}

.card--tours .card__article {
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  gap: clamp(16px, 1.7vw, 24px);
  display: flex;
}

@media only screen and (max-width: 1180px) {
  .card--num .card__item:before {
    bottom: -24px;
  }

  .card--slider .card__item {
    padding-bottom: 60px;
  }

  .card--slider .card__content {
    padding-right: 0;
  }
}

@media only screen and (max-width: 992px) {
  .card__item, .card__item img {
    border-radius: var(--radius-mobile);
  }

  .card:not(.card--slider) .card__items {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
  }

  .card:not(.card--slider) .card__item:last-of-type {
    grid-column: span 2;
  }

  .card:not(.card--slider) .card__item:last-of-type img {
    aspect-ratio: 2 / 1;
  }
}

@media only screen and (max-width: 768px) {
  .card--slider .card__item img {
    aspect-ratio: 3 / 2;
  }
}

@media only screen and (max-width: 576px) {
  .card:not(.card--slider) .card__items {
    grid-template-columns: 1fr;
  }

  .card:not(.card--slider) .card__item:last-of-type {
    grid-column: auto;
  }

  .card:not(.card--slider) .card__item img {
    aspect-ratio: 2 / 1;
  }
}

.box__items--single:not(:last-of-type) {
  margin-bottom: 24px;
}

.box__item {
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  display: flex;
}

.box__item--reverse {
  flex-direction: row-reverse;
}

.box__content {
  width: 100%;
}

.box h3 {
  margin-bottom: 8px;
}

.box__image {
  width: 100%;
  border-radius: var(--radius-main);
  position: relative;
  overflow: hidden;
}

.box__image img {
  width: 100%;
}

.box__accent {
  font-size: 16px;
  line-height: 1.5;
  font-weight: var(--fw-700);
}

.box .text + .box__accent {
  margin-bottom: 8px;
}

.box .title {
  margin-bottom: 24px;
}

.box__list {
  margin-bottom: clamp(24px, 3.4vw, 44px);
  padding-left: 24px;
}

.box__list li {
  list-style: disc;
  display: list-item;
}

.box__list li::marker {
  font-size: 16px;
}

.box__labels {
  align-items: center;
  gap: 8px;
  display: flex;
  position: absolute;
  top: 24px;
  right: 24px;
}

.box__label {
  background-color: var(--color-white);
  border-radius: var(--radius-main);
  padding: 8px 16px;
}

.box__extras {
  background-color: var(--color-white);
  border-radius: var(--radius-main);
  padding: clamp(16px, 2.3vw, 32px) clamp(0px, 2.3vw, 32px);
}

.box__extras:not(:last-of-type) {
  margin-bottom: 24px;
}

.box__extras .box__content {
  padding-top: 8px;
}

.box__extra {
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  gap: 24px;
  display: grid;
}

.box__extra--short {
  grid-template-columns: repeat(3, 1fr);
}

.box__extra--short .box__inner {
  min-height: auto;
}

.box__inner {
  min-height: clamp(280px, 26vw, 375px);
  border: 1px solid var(--color-brown-400);
  border-radius: var(--radius-main);
  flex-direction: column;
  justify-content: space-between;
  padding: clamp(10px, 1.7vw, 24px) clamp(10px, 1.7vw, 22px);
  display: flex;
}

.box__inner > :not(:last-child) {
  margin-bottom: 24px;
}

.box__inner [class].btn {
  font-size: 16px;
  font-weight: var(--fw-600);
  margin-inline: 0;
}

.box:not(.box--simple) .box__extras .box__item {
  padding: 0;
}

.box:not(.box--simple) .box__item {
  background-color: var(--color-white);
  border-radius: var(--radius-main);
  padding: clamp(16px, 2.3vw, 32px) clamp(0px, 2.3vw, 32px);
}

.box:not(.box--simple) .box__item:not(:last-child) {
  margin-bottom: 24px;
}

.box--simple .box__item, .box--full .box__item, .box__extras .box__item {
  gap: clamp(24px, 3.4vw, 48px);
}

.box--simple .box__item {
  padding-right: clamp(40px, 7.2vw, 103px);
}

.box--simple .box__item:not(:last-child) {
  margin-bottom: clamp(30px, 6.2vw, 88px);
}

.box--simple .box__content {
  max-width: 469px;
}

.box--simple .box__item:not(.box--simple .box__item--reverse) .box__content {
  text-align: right;
}

.box--full .box__item {
  align-items: flex-start;
}

.box--full .box__wrapper {
  max-width: 493px;
}

.box--full .box__wrapper > .text {
  margin-bottom: 24px;
}

.box--full .box__wrapper .btn {
  margin-inline: 0;
}

.box--full .box__image {
  max-width: 552px;
  position: relative;
}

.box--guide .box__item {
  align-items: flex-start;
}

.box--guide .box__content, .box--guide .box__title {
  max-width: 552px;
}

.box--guide .box__content {
  padding-top: 14px;
}

.box--guide .box__content .text:not(:last-of-type) {
  margin-bottom: 24px;
}

.box--grand .box__extras > .box__items {
  margin-bottom: clamp(34px, 4.5vw, 64px);
}

.box--grand .box__extras > .box__items + .box__name {
  margin-bottom: 24px;
}

@media only screen and (max-width: 992px) {
  .box__image, .box__extras, .box__inner, .box:not(.box--simple) .box__item {
    border-radius: var(--radius-mobile);
  }

  .box--simple .box__item {
    padding-right: 0;
  }

  .box--full .box__item {
    flex-direction: column-reverse;
    align-items: center;
  }

  .box--full .box__wrapper, .box--full .box__image {
    max-width: 100%;
  }

  .box--full .box__image img {
    aspect-ratio: 3 / 2;
  }
}

@media only screen and (max-width: 768px) {
  .box__item {
    flex-direction: column;
  }

  .box__labels {
    top: 10px;
    right: 10px;
  }

  .box__label {
    padding: 8px;
  }

  .box__extra {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
  }

  .box--simple .box__content {
    max-width: 100%;
  }

  .box--simple .box__content p {
    max-width: 576px;
  }

  .box--simple .box__item:not(.box--simple .box__item--reverse) .box__content {
    text-align: left;
  }

  .box--simple .box__image img {
    aspect-ratio: 5 / 3;
  }

  .box--guide .title {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 576px) {
  .box--simple .box__image img {
    aspect-ratio: 3 / 2;
  }
}

[class].slider__slides, [class].slider__slides li {
  max-width: inherit;
}

[class].slider__pagination, [class].slider__arrows {
  top: unset;
  z-index: 5;
  width: -moz-fit-content;
  width: fit-content;
  background-color: var(--color-white);
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: absolute;
}

[class].slider__pagination {
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);
}

[class].slider__pagination .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background-color: var(--color-brown-400);
  opacity: .2;
  margin: 0;
}

[class].slider__pagination .swiper-pagination-bullet-active {
  opacity: 1;
}

[class].slider__pagination .swiper-pagination-bullet:not(:last-of-type) {
  margin-right: 16px;
}

[class].slider__arrows {
  gap: 16px;
  bottom: clamp(100px, 10.5vw, 152px);
  right: 32px;
}

[class].slider__arrow {
  width: 64px;
  height: 48px;
  border: 2.5px solid var(--color-brown-400);
  border-radius: var(--radius-main);
  padding: 18px 24px;
  position: relative;
}

[class].slider__arrow.swiper-button-disabled {
  opacity: .2;
}

[class].slider__arrow:before {
  content: "";
  width: 16.5px;
  height: 12px;
  background-image: url("../images/icons/arrow-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

[class].slider__arrow--prev:before {
  transform: translate(-50%, -50%)rotate(180deg);
}

[class].slider--destinations [class].slider__arrows {
  bottom: clamp(100px, 12.5vw, 174px);
}

@media only screen and (max-width: 1280px) {
  [class].slider {
    max-width: 90vw;
  }
}

@media only screen and (max-width: 1180px) {
  [class].slider {
    background-color: var(--color-white);
    border-radius: var(--radius-main);
    padding-bottom: 24px;
  }

  [class].slider__arrows, [class].slider__pagination {
    position: initial;
    margin-inline: auto;
  }

  [class].slider__pagination {
    transform: translateX(0);
  }

  [class].slider__arrow {
    z-index: 2;
    position: absolute;
    bottom: 10px;
  }

  [class].slider__arrow--prev {
    left: 24px;
  }

  [class].slider__arrow--next {
    right: 24px;
  }
}

@media only screen and (max-width: 992px) {
  [class].slider {
    border-radius: var(--radius-mobile);
  }
}

@media only screen and (max-width: 576px) {
  [class].slider [class].slider__arrow {
    width: 50px;
    height: 30px;
    padding: 16px 20px;
  }
}

@keyframes toplinecross {
  0% {
    transform: translateY(var(--move-decrement));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes bottomlinecross {
  0% {
    transform: translateY(var(--move));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0)rotate(-45deg);
  }
}

@keyframes toplineburger {
  0% {
    transform: rotate(45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move-decrement)) rotate(0);
  }
}

@keyframes bottomlineburger {
  0% {
    transform: translateY(0)rotate(-45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move)) rotate(0);
  }
}

.policy h1 {
  font-size: clamp(28px, 4.5vw, 64px);
}

.policy h2 {
  font-size: 28px;
}

.policy h1 {
  margin-bottom: clamp(24px, 3.4vw, 48px);
}

.policy h2 {
  margin-bottom: clamp(16px, 2.2vw, 32px);
}

.policy .policy__content:not(:last-child) {
  margin-bottom: clamp(24px, 3.4vw, 48px);
}

.policy .policy__content > :not(:last-child, h2) {
  margin-bottom: clamp(16px, 2.2vw, 32px);
}

.policy ul {
  padding-left: clamp(16px, 2.2vw, 32px);
}

.policy li {
  padding-left: 32px;
  position: relative;
}

.policy li:before {
  content: "✔";
  position: absolute;
  left: 0;
}

.policy li:not(:last-of-type) {
  margin-bottom: 16px;
}

.policy a {
  color: var(--color-brown-400);
  text-underline-offset: 6px;
  font-size: 18px;
  text-decoration: underline;
}

.policy strong {
  font-size: clamp(18px, 3vw, 22px);
  font-weight: var(--fw-500);
}

.policy__inner {
  background-color: var(--color-white);
  border-radius: var(--radius-main);
  padding: clamp(10px, 3vw, 32px);
}

@media only screen and (max-width: 992px) {
  .policy__inner {
    border-radius: var(--radius-mobile);
  }
}

.footer {
  padding: clamp(30px, 6.2vw, 78px) 0 24px;
}

.footer__wrapper, .footer__items {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.footer .copyright br {
  display: none;
}

.footer__items {
  gap: 24px;
}

@media only screen and (max-width: 768px) {
  .footer__wrapper {
    text-align: center;
    flex-direction: column-reverse;
    gap: 30px;
  }
}

@media only screen and (max-width: 475px) {
  .footer .copyright br {
    display: block;
  }
}

/*# sourceMappingURL=main.css.map */
