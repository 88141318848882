@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

html,
body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-500);
  font-style: normal;
  font-size: clamp(16px, 2vw, 20px);
  line-height: 1.5;
  letter-spacing: 0;
  color: var(--color-brown-400);
  background-color: var(--color-cream-100);
  font-optical-sizing: auto;

  &__body {
    min-width: 320px;
    min-height: var(--vh);
  }

  &.open {
    overflow: hidden;
  }
}

.site-container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  min-height: var(--vh);

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-position: 100% 0;
    background-repeat: repeat-y;
    background-image: url("../images/body-bg.svg");

    @include media(tablet) {
      background-size: 40%;
    }
  }
}

.main {
  padding-top: var(--header-height);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
}

.section {
  padding: clamp(30px, 6.2vw, 88px) 0;

  &__title,
  &--more > *:nth-last-child(2) {
    margin-bottom: clamp(24px, 3.4vw, 48px);
  }
}